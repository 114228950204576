import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postApiFormData } from "../helpers/AxiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useFormDataPostHook = ({ queryKey, navigateURL }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate()

  const {
    isLoading: isPostLoading,
    mutate,
    isSuccess: isPostSuccess,
    data: postData,
    isError: isPostError
  } = useMutation(postApiFormData, {
    onSuccess: data => {
      if (data.status === 201 || data.status === 200) {
        toast.success("Success");
        if (navigateURL) {
          navigate(navigateURL)
        }
      } else {
        toast.error("Error occurred",);
      }
    },
    onError: error => {
      toast.error("Error occurred",);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
    }

  });
  return {
    isPostLoading,
    mutate,
    isPostSuccess,
    postData,
    isPostError
  }
};

export default useFormDataPostHook;