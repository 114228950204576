import React, { useState } from "react";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";
import loadable from "@loadable/component";
import usePostHook from "../../customHooks/usePostHook";
import useDeleteHook from "../../customHooks/useDeleteHook";
import usePatchHook from "../../customHooks/usePatchHook";

const NavigationComponent = loadable(() => import("../../components/Navigation/navigation"));


const NavigationEditor = () => {
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const [openEditPopup, setOpenEditPopup] = useState<boolean>(false)

  const [id, setId] = useState()


  //@ts-ignore
  const { data: navigation } = useGetHook({
    queryKey: ["navigation"],
    url: `${APIS.NAVIGATION}`,
  });

  // eslint-disable-next-line no-console

  //  @ts-ignore
  const { data: singleData } = useGetHook({
    queryKey: [`navigation-${id}`],
    url: id ? `${APIS.NAVIGATION}${id}/` : `${APIS.NAVIGATION}`,
  });

  const { mutate } = usePostHook({ queryKey: ["navigation"], navigateURL: "/navigation-editor/" });

  // CREATE_USER
  const handleAddNavigation = async (values) => {
    const url = APIS.NAVIGATION;

    const formData = values;
    try {
      mutate({ url, formData })
      setOpenPopup(false);
    } catch (e) {
      console.log(e)
    }
  }

  //UPDATE_NAVIGATION
  const {
    mutate: patchMutate,

  } = usePatchHook({ queryKey: `navigation-${id}` })
  const handleUpdate = async (values, formikProps) => {

    const payload = {
      ...values,
    };
    const url = `${APIS.NAVIGATION}${id}/`;
    const formData = payload
    try {
      await patchMutate({ url, formData, formikProps });
      setOpenEditPopup(false)
    } catch (e) {
      console.log(e)
    }
  }

  //Delete page

  const {
    mutateAsync: deleteMutate,
  } = useDeleteHook({ queryKey: ['navigation'] })

  const handleDelete = async (itemId) => {
    const url: any = `${APIS.NAVIGATION}${itemId}/`;

    try {
      deleteMutate({ url })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <NavigationComponent
        navigation={navigation}
        onSubmit={handleAddNavigation}
        handleDelete={handleDelete}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        openEditPopup={openEditPopup}
        setOpenEditPopup={setOpenEditPopup}
        setId={setId}
        singleData={singleData}
        handleUpdate={handleUpdate}
      />
    </>
  )

};

export default NavigationEditor;
