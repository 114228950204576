import loadable from "@loadable/component";
import React, { useState } from 'react'
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";
import usePatchHook from "../../customHooks/usePatchHook";
import { useParams } from "react-router-dom";

const DeliveryPartnerComponent = loadable(() => import("../../components/DeliveryPartner/deliveryPartner"));


const DeliveryPartner = () => {
  const [id, setId] = useState()
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const {
    isLoading: isPatchLoading,
    mutate,

  } = usePatchHook({ queryKey: `DeliveryPartners` })
  const handleUpdate = async (values: any) => {
    const url = `${APIS.DELIVERY_PARTNER}${values?.object_id}/`;
    const formData = {
      data: JSON.stringify({
        key_id: values?.KeyID,
        developer_id: values?.DeveloperID,
        signing_secret: values?.SigningSecret
      })
    }
    try {
      await mutate({ url, formData });
      setOpenPopup(false)
    } catch (e) {
      console.log(e)
    }
    console.log(values)
  }
  const handleIsActive = async (e: any, id: any) => {

    const payload = {
      is_active: e.target.checked
    };

    const url = `${APIS.DELIVERY_PARTNER}${id}/`;
    const formData = payload
    try {
      await mutate({ url, formData });
      setOpenPopup(false)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <DeliveryPartnerComponent
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      id={id}
      setId={setId}
      handleUpdate={handleUpdate}
      handleIsActive={handleIsActive}
    />
  )
}

export default DeliveryPartner