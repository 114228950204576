import React, { useState } from 'react'
import loadable from "@loadable/component";
import usePostHook from '../../customHooks/usePostHook';
import APIS from '../../contants/EndPoints';
import useDeleteHook from '../../customHooks/useDeleteHook';

const ModalComponent = loadable(() => import("../../components/Modal/modal"));

const Modal = () => {
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const { mutate } = usePostHook({ queryKey: ["modal"], navigateURL: "/modal" });

  // CREATE_USER
  const handleAddModal = async (values) => {
    const url = APIS.PAGES;

    const formData = {
      title: values?.title,
      is_popup: true
    };


    try {
      mutate({ url, formData })
      setOpenPopup(false)
    } catch (e) {
      console.log(e)
    }
  }
  const {
    isLoading: isDeleteLoading,
    mutateAsync: deleteMutateAsync,
  } = useDeleteHook({ queryKey: ['modal'] })


  const handleDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.PAGES}${pk}/`
    try {
      const res = await deleteMutateAsync({ url })
      console.log(res, "ii")
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <ModalComponent
      handleAddModal={handleAddModal}
      setOpenPopup={setOpenPopup}
      openPopup={openPopup}
      handleDelete={handleDelete}
    />
  )
}

export default Modal