const APIS = {
  //Navigation
  NAVIGATION: `/navigation/`,

  //BASIC_WEBSITE_INFO
  BASIC_WEBSITE_INFO: `/basic-website-info/`,

  // Pages
  PAGES: `/pages/`,
  MEDIA: `/media/`,
  SEO_META_DATA: `/seo-meta-tags/`,


  //THEMES
  THEMES: `/theme/`,
  CHANGE_THEME: `/change-theme/`,
  THEME_COMPONENTS: `/theme-templates/`,
  SAVE_AS_THEME: `/save-as-theme/`,
  RESET_THEME_CONTENT: `/reset-theme-content/`,

  // PREVIEW_DOMAIN
  PREVIEW_DOMAIN: `/preview-domain/`,
  //PAYMENT_GATEWAY
  PAYMENT_GATEWAY: `/payment-gateway/`,

  //DELIVERY_METHOD
  DELIVERY_METHOD: `/delivery-method/`,
  DELIVERY_PARTNER: `/delivery-partner/`,
};

export default APIS;
