import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchApiFormData } from "../helpers/AxiosInstance";
import { toast } from "react-toastify";

const useFormDataPatchHook = ({ queryKey }) => {
  //getting cached data from queryClient
  const queryClient = useQueryClient();

  const {
    isLoading: isUpdateLoading,
    mutateAsync,
    isSuccess: isUpdateSuccess,
    data: updateData,
    isError: isUpdateError,
  } = useMutation(patchApiFormData, {
    onSuccess: (data) => {
      if (data.status === 200 || data.status === 201) {
        toast.success(data?.data?.message ?? "Success");
      }
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      //  When the mutation succeeds, invalidate or re-render queries with provided queryKey
      //  (or re-render particular component)
      queryClient.invalidateQueries(queryKey);
    },
  });

  return {
    isUpdateLoading,
    mutateAsync,
    isUpdateSuccess,
    updateData,
  };
};

export default useFormDataPatchHook;
