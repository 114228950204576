import React, { useState } from "react";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../contants/EndPoints";
import loadable from "@loadable/component";
import usePostHook from "../../customHooks/usePostHook";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../components/Reusable/Spinner/DataLoadSpinner";

const ThemeComponent: any = Loadable({
  loader: () => import("../../components/Theme"),
  loading: () => <DataLoadSpinner />,
});

const Theme = () => {
  const [themeId, setThemeId] = useState<string>("");
  //@ts-ignore
  const { data: themes, isLoading } = useGetHook({
    queryKey: ["themes"],
    url: `${APIS.THEMES}`,
  });

  const { isPostLoading, mutate } = usePostHook({
    queryKey: ["change-theme"],
    navigateURL: "/themes",
  });

  // CREATE_USER
  const handleActivateTheme = async () => {
    const url = APIS.CHANGE_THEME;

    const formData = {
      theme_id: themeId,
    };
    try {
      await mutate({ url, formData });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {isLoading ? (
        <DataLoadSpinner />
      ) : (
        <ThemeComponent
          themes={themes}
          themeId={themeId}
          setThemeId={setThemeId}
          isLoading={isPostLoading}
          handleActivateTheme={handleActivateTheme}
        />
      )}
    </>
  );
};

export default Theme;
