import React, { useState } from "react";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";
import loadable from "@loadable/component";
import usePatchHook from "../../customHooks/usePatchHook";


const DeliveryMethodComponent = loadable(() => import("../../components/DeliveryMethod/deliverymethod"));

const DeliveryMethod = () => {
  //@ts-ignore
  const { isLoading: loading, data: deliveryMethodData } = useGetHook({
    queryKey: ["DeliveryMethod"],
    url: `${APIS.DELIVERY_METHOD}`,
  });

  const [id, setId] = useState()
  const [openPopup, setOpenPopup] = useState<boolean>(false)

  //@ts-ignore
  const { isLoading: singleLoading, data: singleData } = useGetHook({
    queryKey: [`paymentmethods-${id}`],
    url: id ? `${APIS.DELIVERY_METHOD}${id}/` : `${APIS.DELIVERY_METHOD}`,
  });

  const {
    isLoading: isPatchLoading,
    mutate,

  } = usePatchHook({ queryKey: `paymentmethod-${id}` })
  const handleUpdate = async (values: any) => {

    const payload = {
      ...values,
    };
    const url = `${APIS.DELIVERY_METHOD}${id}/`;
    const formData = payload
    try {
      await mutate({ url, formData });
      setOpenPopup(false)
    } catch (e) {
      console.log(e)
    }
  }

  const handleIsActive = async (e: any, id: any) => {

    const payload = {
      is_active: e.target.checked
    };

    const url = `${APIS.DELIVERY_METHOD}${id}/`;
    const formData = payload
    try {
      await mutate({ url, formData });
      setOpenPopup(false)
    } catch (e) {
      console.log(e)
    }
  }




  return (
    <>
      <DeliveryMethodComponent
        deliveryMethodData={deliveryMethodData}
        setId={setId}
        handleUpdate={handleUpdate}
        singleData={singleData}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        handleIsActive={handleIsActive}
      />
    </>
  );
};

export default DeliveryMethod;
