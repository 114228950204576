import { useState } from "react";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";
import loadable from "@loadable/component";
import usePatchHook from "../../customHooks/usePatchHook";


const PaymentMethodComponent = loadable(() => import("../../components/PaymentMethod/paymentmethod"));

const PaymentMethod = () => {
  //@ts-ignore
  const { isLoading: loading, data: paymentMethodData } = useGetHook({
    queryKey: ["paymentmethod"],
    url: `${APIS.PAYMENT_GATEWAY}`,
  });

  const [id,setId] =useState()
  const [openPopup, setOpenPopup] = useState<boolean>(false)


  //@ts-ignore
  const { isLoading: singleLoading, data: singleData } = useGetHook({
    queryKey: [`paymentmethods-${id}`],
    url: id ? `${APIS.PAYMENT_GATEWAY}${id}/`: `${APIS.PAYMENT_GATEWAY}`,
  });

  const {
    isLoading:isPatchLoading,
    mutate,
   
  } = usePatchHook({ queryKey: `paymentmethod-${id}` })

  const handleUpdate = async (values, formikProps) => {
    
    const payload = {
      content:JSON.stringify({
        secret_key:values?.secretKey,
        public_key:values?.publicKey,
      }),
      service_charge:values?.serviceCharge
    };

    const url = `${APIS.PAYMENT_GATEWAY}${id}/`;
    const formData = payload
    try {
      await mutate({ url, formData, formikProps });
      setOpenPopup(false)
    } catch (e) {
      console.log(e)
    }
  }

  const handleIsActive = async (e:any,id:any) => {
    
    const payload = {
    is_active: e.target.checked
    };

    const url = `${APIS.PAYMENT_GATEWAY}${id}/`;
    const formData = payload
    try {
      await mutate({ url, formData });
      setOpenPopup(false)
    } catch (e) {
      console.log(e)
    }
  }


  return (
    <>
      <PaymentMethodComponent 
      paymentMethodData={paymentMethodData}
      handleUpdate={handleUpdate}
      setId={setId}
      singleData={singleData}
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
      handleIsActive={handleIsActive}
      />
    </>
  );
};

export default PaymentMethod;
