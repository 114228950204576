// @ts-nocheck
import React, { useState, useEffect } from "react";
import CodeEditor from "../../components/Reusable/CodeEditor";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../contants/EndPoints";
import { useParams } from "react-router-dom";
import usePatchHook from "../../customHooks/usePatchHook";
import { PrimaryButton } from '@dibtech/styleguide'

const ComponentEditor = () => {
  const { id } = useParams();
  const { isLoading: themeComponentLoading, data: themeComponent } = useGetHook(
    {
      queryKey: [`themeComponents-${id}`],
      url: `${APIS.THEME_COMPONENTS}${id}/`,
    }
  );
  const {
    mutate,
  } = usePatchHook({ queryKey: `themeComponents-${id}` })

  const handleSaveComponent = async (values) => {

    const payload = {
      content: values
    };
    const url = `${APIS.THEME_COMPONENTS}${id}/`;
    const formData = payload
    try {
      await mutate({ url, formData });
    } catch (e) {
      console.log(e)
    }
  }
  // eslint-disable-next-line no-console
  const [code, setCode] = useState("");

  useEffect(() => {
    setCode(themeComponent?.content ?? "")
  }, [themeComponent])


  return (
    <>
      {themeComponentLoading ? (
        "Loading"
      ) : (
        <div>
          <CodeEditor code={code} setCode={setCode} />
          <div className=' flex justify-end p-4 gap-4'>

            <PrimaryButton
              onClick={() => handleSaveComponent(code)}
              text={"Save"}
            />

          </div>

        </div>

      )}
    </>
  );
};

export default ComponentEditor;
