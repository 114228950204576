import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PageEditor from "../pages/PageEditor";
import EditorLayout from "../layouts/EditorLayout";
import Pages from "../pages/WebPage/pages";
import PageNotFound from "../pages/ErrorPage/PageNotFound";
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../pages/Dashboard";
import NavigationEditor from "../pages/Navigation/NavigationEditor";
import Theme from "../pages/Theme/Themes";
import Component from "../pages/Component/Components";
import Setting from "../pages/Setting";
import ComponentEditor from "../pages/Component/ComponentEditor";
import PaymentMethod from "../pages/PaymentMethod/paymentmethod";
import DeliveryMethod from "../pages/DeliveryMethod/deliverymethod";
import Modal from "../pages/Modal/modal";
import DeliveryPartner from "../pages/DeliveryPartner/deliveryPartner";
// import DashboardLayout from "../layouts/DashboardLayout";

// import AccessControl from "../helpers/accessControl";
// import PermissionDenied from "../pages/Error/PermissionDenied";

// https://adarshaacharya.com.np/blog/role-based-auth-with-react-router-v6

/**
 * Top level application router
 *
 * @returns {JSX.Element}
 */
const Router = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        <Route path={"/"} element={<Dashboard />} />
        <Route path={"/pages"} element={<Pages />} />
        <Route path={"/navigation-editor"} element={<NavigationEditor />} />
        <Route path={"/themes"} element={<Theme />} />
        <Route path={"/modal"} element={<Modal />} />
        <Route path={"/components"} element={<Component />} />
        <Route path={"/component-editor/:id"} element={<ComponentEditor />} />
        <Route path={"/settings"} element={<Setting />} />
        <Route path="/*" element={<PageNotFound />} />
        <Route path={"/paymentmethod"} element={<PaymentMethod />} />
        <Route path={"/deliverymethod"} element={<DeliveryMethod />} />
        <Route path={"/deliverypartner"} element={<DeliveryPartner />} />

      </Route>
      <Route element={<PrivateRoute children={<EditorLayout />} />}>
        <Route path={"/editor/:ID"} element={<PageEditor />} />
      </Route>
    </Routes>
  );
};

export default Router;
