import React from "react";
import ServiceIcon from "../assets/icons/services.svg";
import ClientIcon from "../assets/icons/clients.svg";
import { NavLink, Outlet } from "react-router-dom";
import DashboardIcon from "../assets/icons/dashboard.svg";
import PagesIcon from "../assets/icons/pages.svg";
import NavigationIcon from "../assets/icons/navigation.svg";
import ThemeIcon from "../assets/icons/theme.svg";
import ComponentIcon from "../assets/icons/component.svg";
import SettingIcon from "../assets/icons/setting.svg";
import PaymentIcon from "../assets/icons/payment.svg";
import DeliveryIcon from "../assets/icons/delivery.svg";

//@ts-ignore
import { Layout } from "@dibtech/styleguide";

const navigation: any = [
  { name: "Dashboard", href: "/", icon: DashboardIcon },
  { name: "Page", href: "/pages", icon: PagesIcon },
  { name: "Modal", href: "/modal", icon: PagesIcon },
  { name: "Navigation", href: "/navigation-editor", icon: NavigationIcon },
  { name: "Theme", href: "/themes", icon: ThemeIcon },
  { name: "Component", href: "/components", icon: ComponentIcon },
  { name: "Setting", href: "/settings", icon: SettingIcon },
  { name: "Payment Method", href: "/paymentmethod", icon: PaymentIcon },
  { name: "Delivery Method", href: "/deliverymethod", icon: DeliveryIcon },
  { name: "Delivery Partner", href: "/deliverypartner", icon: DeliveryIcon },


];

const DashboardLayout = () => {
  return (
    <Layout
      children={<Outlet />}
      navigationComponent={
        <nav className="px-2 space-y-1">
          {navigation?.map((item: any) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive, isPending }) => {
                return `${isActive ? "bg-[#5b6ab0] text-white" : isPending ? "text-indigo-100 hover:bg-indigo-600" : ""} group flex gap-2 items-center px-2 py-2 text-sm font-medium rounded-md`;
              }}
            >
              <img src={item.icon} alt="" className="text-indigo-300" />
              {item.name}
            </NavLink>
          ))}
        </nav>
      }
    />
  );
};

export default DashboardLayout;
