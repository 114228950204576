//@ts-ignore
import Axios from "axios";
import { accessToken } from "./sessionKey";

const API_ROOT: string = "https://dibbuilder.dibsolutions.com.au/api";

export default API_ROOT;
//create axios instance
export const instance = Axios.create({
  baseURL: `${API_ROOT}`,
  headers: {
    "Content-Type": "application/json",
    Authorization: `JWT ${accessToken()}`,
  },
});

// create axios instance for form data to upload file
export const formDataInstance = Axios.create({
  baseURL: `${API_ROOT}`,
  headers: {
    "Content-Type": `multipart/form-data`,
  },
});

// create form data to upload files
export const postApiFormData = async (fileData) => {
  const { url, formData, formikProps } = fileData;
  let response;
  try {
    response = await formDataInstance({
      method: "POST",
      url: `${url}`,
      headers: {
        Authorization: `JWT ${accessToken()}`,
      },
      data: formData,
      transformResponse: [
        function (responseData) {
          //Do what ever you want transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    formikProps?.setErrors(e.response.data);
    return e.response;
  }
  return response;
};
// create form data to upload files
export const patchApiFormData = async (fileData) => {

  const { url, formData } = fileData;
  let response;
  try {
    response = await formDataInstance({
      method: "PATCH",
      url: `${url}`,
      headers: {
        Authorization: `JWT ${accessToken()}`,
        "Content-Type": `multipart/form-data`
      },
      data: formData,
      transformResponse: [
        function (responseData) {
          //Do what ever you want transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    console.log(e);

  }
  return response;
};

//get data from api
export const getApiData = async (url, param = null) => {
  let response;
  try {
    response = await instance({
      method: "GET",
      url: `${url}`,
      params: param,
      // headers: {
      //   Authorization: `JWT ${accessToken()}`,
      // },
      transformResponse: [
        function (responseData) {
          // Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    return e.response;
  }
  return response;
};

//post data to api
export const postApiData = async (data) => {
  const { url, formData, formikProps } = data;
  let response;
  try {
    response = await instance({
      method: "POST",
      url: `${url}`,
      data: formData,
      headers: {
        Authorization: `JWT ${accessToken()}`,
      },
      transformResponse: [
        function (responseData) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    formikProps.setErrors(e.response.data);
    return e.response;
  }
  return response;
};

//post data to api
export const postApiDataNoAuth = async (data) => {
  const { url, formData } = data;
  let response;
  try {
    response = await instance({
      method: "POST",
      url: `${url}`,
      data: formData,
      headers: {
        // Authorization: `JWT ${accessToken()}`,
      },
      transformResponse: [
        function (responseData) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    return e.response;
  }
  return response;
};

//update data
export const putApiData = async (data) => {
  const { url, formData, formikProps } = data;

  // eslint-disable-next-line no-console
  let response;
  try {
    response = await instance({
      method: "PUT",
      url: `${url}`,
      data: formData,
      headers: {
        Authorization: `JWT ${accessToken()}`,
      },
      transformResponse: [
        function (responseData) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    formikProps.setErrors(e.response.data);
    return e.response;
  }
  return response;
};

//delete data
export const deleteApiData = async ({ url }) => {
  let response;
  response = await instance({
    method: "DELETE",
    url: url,
    // params: param,
    headers: {
      Authorization: `JWT ${accessToken()}`,
    },
  });
  return response;
};

//delete bulk data
export const bulkDeleteApiData = async (data) => {
  const { url, formData } = data;
  let response;
  response = await instance({
    method: "DELETE",
    url: `${url}`,
    data: formData,
    // params: param,
    headers: {
      Authorization: `JWT ${accessToken()}`,
    },
  });
  return response;
};

//update data
export const patchApiData = async (data) => {
  const { url, formData } = data;
  let response;
  try {
    response = await instance({
      method: "PATCH",
      url: `${url}`,
      data: formData,
      headers: {
        Authorization: `JWT ${accessToken()}`,
      },
      transformResponse: [
        function (responseData) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    // formikProps.setErrors(e.response.data);
    return e.response;
  }
  return response;
};



export { API_ROOT };
